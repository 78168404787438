import React from 'react';
import Layout from '../shared/Layout';
import DoubleLayout from '../shared/DoubleLayout';
import Head from '../shared/Head';
import cookiesStyles from './cookies.module.scss';

const CookiesPolicy = () => {
    return (
        <Layout>
            <Head title={'Cookies'} description={`Câteva informații despre cookies și cum sunt utilizate pe site.`}/>
            <DoubleLayout>
                <div className={cookiesStyles.wrapper}>
                    <h1>Politica de utilizare Cookies</h1>
                    <p className={cookiesStyles.bold}>Politica de utilizare „Cookies”</p>
                    <p>MintWebTutsBlog.ro foloseste cookie-uri proprii si de la terti 
                        pentru a furniza vizitatorilor o experienta mult mai buna de navigare
                         si servicii adaptate nevoilor si interesului fiecaruia.</p>
                    <p className={cookiesStyles.bold}>1. Ce sunt „cookies”?</p>
                    <p>Un „cookie” este un mic fisier, de regula criptat, locat in dosarele
                     navigatorului web pe care il folosesti. „Cookie”-urile sunt create de catre 
                     producătorii de continut on-line pentru utilizatorii site-urilor in scopul de a-i 
                     ajuta pe acestia sa navigheze pe internet intr-un mod mai eficient si pentru a 
                     acorda accesul la anumite functii. Aceste fisiere nu sunt virusi. Cum aceste fisiere
                     nu conțin coduri, ele nu pot fi executate si nici nu pot accesa alte fisiere sau
                      dosare din calculatorul tau. In conditiile in care ele stocheaza informatii despre 
                      navigarea pe internet a unui utilizator, atat adrese specifice cat si navigare printre 
                      diverse site-uri, „cookie”-urile pot fi folosite ca o forma de spyware. 
                      Programele anti-spyware, fiind constiente de aceasta posibila problema,
                       elimina anumite „cookie”-uri in urma controalelor de rutina.</p>
                       <p className={cookiesStyles.bold}>2. De ce folosim „cookies”?</p>
                        <p>Utilizam „cookie”-uri pentru a furniza utilizatorilor MintWebTutsBlog.ro o experienta
                            mai buna de navigare si servicii adaptate nevoilor si interesulor tale, pentru analize 
                            statistice cu privire la modul in care este utilizata pagina de internet, in scopul 
                            imbunatatirii site-ului. Cookie-urile ne ajuta la:</p>
                            <ul>
                                <li>personalizarea anumitor setari precum: limba in care este vizualizat un site, pastrarea optiunilor pentru diverse produse/servicii.</li>
                                <li>ne ofera un feedback valoros asupra modului cum este utilizat site-ul de catre utilizatori, astfel incat sa il putem face si mai eficient si mai accesibil pentru utilizatori.</li>
                                <li>permit aplicatiilor multimedia sau de alt tip de pe alte site-uri sa fie incluse intr-un anumit site pentru a crea o experienta de navigare mai valoroasa, mai utila si mai placuta;</li>
                                <li>imbunatatesc eficienta publicitatii online.</li>
                            </ul>
                            <p>Uneori, „cookie”-urile pot fi folosite pentru a tine urma vizitelor tale pe pagini de internet terte.</p>
                            <p className={cookiesStyles.bold}>3. Ce fel de „cookie”-uri folosim?</p>
                            <p>Din punct de vedere temporal al stocarii folosim:</p>
                            <ul>
                                <li>„Cookie”-uri fixe; acestea raman pe dispozitivul utilizatorului o perioada determinata de timp, specificata in „cookie”. Acestea sunt folosite de fiecare data cand utilizatorul viziteaza adresa care a creat acel fisier.</li>
                                <li>„Cookie”-uri de sesiune; aceste fisiere sunt temporare. Ele permit operatorilor site-ului web sa conecteze actiunile pe care un utilizator le intreprinde in timpul unei sesiuni de navighare pe internet.</li>
                            </ul>
                            <p>De asemenea, din punct de vedere al functionalitatii, folosim patru tipuri diferite de „cookie”-uri:</p>
                            <ul>
                                <li>„Cookie”-uri strict necesare; acestea sunt esentiale pentru a putea naviga pe pagina de internet si pentru a-i folosi aplicatiile. In lipsa acestora, utilizatorii nu ar putea sa foloseasca servicii de baza precum crearea unui cont sau folosirea unui cos de cumparaturi. Aceste fisiere nu colecteaza informatii despre utilizatori care ar putea fi folosite in scopuri de marketing sau urmarire a utilizatorului pe internet.</li>
                                <li>„Cookie”-uri de performanta; acestea colecteaza date statistice in mod anonim referitor la modul de utilizare al paginii de internet. Ele nu contin informatii personale si sunt folosite pentru a imbunatati experienta utilizatorului atunci cand navigheaza pe pagina de internet.</li>
                                <li>„Cookie”-uri pentru functionalitate; prin intermediul lor, operatorul le permite utilizatorilor sa aleaga diferite optiuni si caracteristici ale paginii web. Spre exemplu, unele „cookie”-uri de functionalitate sunt posibilitatea de a opta pentru memorarea parolei, preferintele de limba, regiune, posibilitatea de a furniza informatii personale precum locatia actuala sau date despre vremea sau traficul din locatia utilizatorului.</li>
                                <li>„Cookie”-uri publicitare si de targetting; folosite pentru a imbunatati relevana reclamelor care iti sunt livrate. Acestea pot, de asemenea, sa limiteze numarul de afisari ale unei reclame considerate irelevante sau sa urmareasca efectivitatea unei campanii publicitare prin mentinerea unei statistice referitoare la numarul de accesari din partea utilizatorilor. De asemenea, o functie importanta pe care unele dintre aceste „cookie”-uri o au este oferirea de securitate in cazul tranzactiilor online. In mod obisnuit, aceste „cookie”-uri sunt folosite folosite de catre terti operatori, putand sa faca o conexiune intre paginile web vizitate de utilizator, neavand insa posibilitatea sa identifice identitatea utilizatorului, intrucat intre profilul tau online si datele prelucrate nu se face nici o conexiune.</li>
                            </ul>
                            <p>Avem „cookie”-uri terte pe site-ul nostru. Tertii, cum ar fi companiile de publicitate sau furnizorii de servicii externe, precum analiza traficului de internet, pot utiliza „cookie”-uri sau alte „plug-in” -uri similare pentru a colecta informatii despre vizita ta pe pagina noastra web.</p>
                            <p className={cookiesStyles.bold}>4. Cum poti sterge „cookie”-urile?</p>
                            <p>Pentru inceput ar trebui sa stii ca, odata cu stergerea „cookie”-urilor, toate preferintele si setarile referitoare la pagina web vor fi sterse. Asemenea, unele pagini web nu vor mai functiona corespunzator sau unele functii vor disparea in cazul eliminarii „cookie”-urilor.</p>
                            <p>Nu recomandam, din aceste motive, optarea respingerii „cookie”-urilor atunci cand vizitezi pagina noastra web. Cele mai multe browsere accepta „cookie”-urile in mod automat, dar poti schimba aceasta optiune in functie de browserul pe care it folosesti.</p>
                            <p>Pentru a face asta, in mod obisnuit, trebuie sa accesezi opțiunea „setari” sau „preferinte” a browserului. Pentru mai multe detalii poti accesa unul dintre linkurile de mai jos:</p>
                            <p>Opera: <a className={cookiesStyles.red} href="https://www.opera.com/help/tutorials/security/cookies/">https://www.opera.com/help/tutorials/security/cookies/</a></p>
                            <p>Firefox: <a className={cookiesStyles.red} href="https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies</a></p>
                                <p>Chrome: <a className={cookiesStyles.red} href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a></p>
                                <p>Safari: <a className={cookiesStyles.red} href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a></p>
                           <p className={cookiesStyles.bold}>5. Securitate si probleme legate de confidentialitate</p>
                           <p>Cookie-urile NU sunt virusi! Nu sunt alcatuite din bucati de cod asa ca nu pot fi executate, nici nu pot auto-rula, nu se pot duplica sau replica pe alte retele pentru a se rula sau replica din nou.
                                In general, browserele au integrate setari de confidentialitate care furnizeaza diferite nivele de acceptare a cookie-rilor, perioada de valabilitate si stergere automata dupa ce utilizatorul a vizitat un anumit site.</p>
                           <p>Cookie-urile nu sunt periculoase, dar, pentru ca prin intermediul lor se transmit in mod constant in ambele sensuri informatii intre browser si website, daca un atacator sau persoana neautorizata intervine in parcursul de transmitere a datelor, informatiile continute de cookie pot fi interceptate. Desi foarte rar, acest lucru se poate întampla daca browserul se conecteaza la server folosind o retea necriptata (ex: o retea WiFi nesecurizata).
                            Alte atacuri bazate pe cookie implica setari gresite ale cookieurilor pe servere, care pot fi exploatate de catre atacatori.</p>  
                            <p>Este important sa cunosti ca dezactivarea cookie-urilor nu va permite accesul utilizatorilor pe site-urile cele mai raspandite si utilizate printre care Gmail, Yahoo, Youtube si altele. Exista cateva masuri de siguranta de baza pe care le poti adopta pentru a naviga in siguranta cu ajutorul cookie-urilor.</p>
                            <ul>
                                <li>Particularizeaza setarile browserului in ceea ce priveste cookie-urile pentru a reflecta un nivel confortabil pentru tine al securitatii utilizarii cookie-urilor. Poti seta termene lungi de expirare pentru stocarea istoricului de navigare si al datelor personale de acces. Daca utilizezi calculatorul impreuna cu alte persoane, poti seta browserul pentru a sterge datele individuale de navigare de fiecare data cand inchizi browserul</li>
                                <li>Instaleaza si updatateaza constant aplicatii antispyware.</li>
                                <li>Asigura-te ca ai browserul mereu updatat. Multe dintre atacurile bazate pe cookies se realizeaza exploatand punctele slabe ale versiunilor vechi ale browserelor.</li>
                            </ul>


                </div>
            </DoubleLayout>
        </Layout>
    );
}
export default CookiesPolicy;